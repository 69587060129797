<template>
  <div class="content-footer">
    주)디지털문깡 I 대표이사 문강명 I 사업자등록번호 847-85-00478 I 통신판매신고번호 제2017-대구수성구-0481호
  </div>
</template>

<script>

export default {
  name: 'ContentFooter'
}
</script>

<style scoped>

</style>
