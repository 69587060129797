<template>
  <nav class="leftMenu_display">
    <div id="leftMenu">
      <div id="nav">
        <ul>
          <li class="menu_01 clickable" title="Home" @click.prevent="$router.replace({ name: 'home' })"><a href="#"></a></li>
          <li class="menu_02 clickable" title="Program" @click.prevent="$router.push({ name: 'program.level' })"><a href="#"></a></li>
          <li class="menu_03 clickable" title="My Page" @click.prevent="$router.push({ name: 'my-page' })"><a href="#"></a></li>
          <li class="menu_04 clickable" title="Study Room" @click.prevent="$router.push({ name: 'study-room' })"><a href="#"></a></li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>

export default {
  name: 'LeftMenu',
  computed: {
    isUserLoggedIn () {
      return this.$store.getters.isLoggedIn
    }
  }
}
</script>

<style scoped>

</style>
