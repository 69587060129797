<template>
  <section class="studybox_top_menu">
    <div class="studybox_logout_box">
      <div class="top-menu-user-details">
        <span class="mr-10" v-text="displayText" v-if="displayText !== null"/>
        <span v-if="isUserLoggedIn"><span v-text="userData.name"></span><span v-if="userData.enrollment"> (Level {{ userData.enrollment.level_id }})</span></span>
        <span class="clickable login-btn" v-else @click.prevent="$router.push({ name: 'login' })">LOGIN</span>
      </div>
      <a href="#"><img src="/image/common/top_ic_logout.png" alt="로그아웃 버튼" style="display:none;"/></a>
      <a href="#"><img src="/image/common/top_ic_login.png" alt="로그인 버튼" /></a>
    </div>
    <div class="all_menu_display">
      <div class="menu_btn" @click="openTopMenu">
        <a href="#"> 전체메뉴</a>
      </div>
      <div class="menu_bg"></div>
      <div class="sidebar_menu">
        <div class="mobile_menu_close_btn" @click="closeTopMenu">
          <a href="#">
            <img src="/image/sub/study_close_btn.png" alt="모바일 메뉴 닫기" />
          </a>
        </div>
        <div class="menu_wrap">
          <ul>
            <li class="all_menu_title_home">
              <a href="#" @click.prevent="$router.push({ name: 'home' })">HOME</a>
            </li>
            <li v-if="isUserLoggedIn">
              <a href="#" @click.prevent="logout">LOGOUT</a>
            </li>
            <li v-if="!isUserLoggedIn">
              <a href="#" @click.prevent="$router.push({ name: 'login' })">LOGIN</a>
            </li>
            <li v-if="!isUserLoggedIn">
              <a href="#" @click.prevent="$router.push({ name: 'register' })">REGISTER</a>
            </li>
          </ul>
          <ul>
            <li class="all_menu_title_program"><a href="#" @click.prevent="$router.push({ name: 'program' })">학습 프로그램</a></li>
            <li><a href="#" @click.prevent="$router.push({ name: 'program.level' })">레벨 선택</a></li>
            <li><a href="#" @click.prevent="$router.push({ name: 'program.level.class-per-week',params: { levelId: 1 } })">상품 선택</a></li>
            <li><a href="#" @click.prevent="$router.push({ name: 'program.time' })">화상수업 시간 선택</a></li>
          </ul>
          <ul>
            <li class="all_menu_title_mypage"><a href="#" @click.prevent="$router.push({ name: 'my-page' })">마이 페이지</a></li>
            <li><a href="#" @click.prevent="$router.push({ name: 'my-page' })">마이 페이지</a></li>
            <li><a href="#" @click.prevent="$router.push({ name: 'my-page.point' })">포인트</a></li>
            <li><a href="#" @click.prevent="$router.push({ name: 'my-page.point02' })">포인트 충전</a></li>
            <li><a href="#" @click.prevent="$router.push({ name: 'notification' })">알림</a></li>
            <li><a href="#" @click.prevent="$router.push({ name: 'my-page.my-info' })">내 정보</a></li>
          </ul>
          <ul>
            <li class="all_menu_title_study"><a href="#" @click.prevent="$router.push({ name: 'study-room' })">공부방</a></li>
            <li><a href="#" @click.prevent="$router.push({ name: 'study-room' })">공부방</a></li>
            <li><a href="#" @click.prevent="$router.push({ name: 'study-room.study-result' })">학습결과 전체보기</a></li>
            <li><a href="#" @click.prevent="$router.push({ name: 'study-room.weekly' })">이번주 공부방</a></li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'

export default {
  name: 'TopMenu',
  props: {
    displayText: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    isUserLoggedIn () {
      return this.$store.getters.isLoggedIn
    },
    userData () {
      return this.$store.getters.userData
    }
  },
  methods: {
    async logout () {
      try {
        await useJwt.logout()
        useJwt.removeTokens()

        this.$ability.update(initialAbility) // Reset ability

        this.$store.dispatch('TOGGLE_IS_LOGGED_IN', false)
        this.$store.dispatch('SET_USER_DATA', null)

        await this.$router.replace({ name: 'home' })
      } catch (e) {
        console.log(e)
      }
    },
    openTopMenu () {
      /* eslint-disable */
      $('.menu_bg').show();
      $('.sidebar_menu').animate({
          right:0
      });
      /* eslint-enable */
    },
    closeTopMenu () {
      /* eslint-disable */
      $('.mobile_menu_close_btn>a').on('click', function(){
          $('.menu_bg').hide();
          $('.sidebar_menu').animate({
              right: '-' + 80 + '%'
          });
      });
      /* eslint-enable */
    }
  }
}

</script>

<style scoped>

</style>
