<template>
  <!-- footer -->
  <footer>
    <p class="footer-logout clickable" @click.prevent="logout" v-if="isUserLoggedIn">
      LOGOUT
    </p>
    <div class="footer_inner">
      <img src="/image/common/copy_logo.png" alt="카피 로고"/>
    </div>
  </footer>
  <!-- footer -->
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'

export default {
  name: 'Footer',
  computed: {
    isUserLoggedIn () {
      return this.$store.getters.isLoggedIn
    }
  },
  methods: {
    async logout () {
      try {
        await useJwt.logout()
        useJwt.removeTokens()

        this.$ability.update(initialAbility) // Reset ability

        this.$store.dispatch('TOGGLE_IS_LOGGED_IN', false)
        this.$store.dispatch('SET_USER_DATA', null)

        await this.$router.replace({ name: 'home' })
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style scoped>

</style>
